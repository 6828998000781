import {
    SCRIPT_LOADER_LOAD_ERROR,
    SCRIPT_LOADER_OTHER_ERROR,
    ScriptLoadingHelper,
} from '../../qaamgo/modules/sat/helper/promise-script-loader';
import { testIsFunction, testIsNonEmptyString, testIsNotNull } from '../../lib/test-and-assert/test-base';
import { globalLogger } from '../../qaamgo/helper/global-logger';

function isCaptchaApiLoaded() {
    return (
        testIsFunction(grecaptcha?.execute) &&
        testIsFunction(grecaptcha?.getResponse) &&
        testIsFunction(grecaptcha?.ready) &&
        testIsFunction(grecaptcha?.render) &&
        testIsFunction(grecaptcha?.reset)
    );
}

const CAPTCHA_ERROR_SCRIPT_LOAD_FAIL = 'captcha-error-script-load-fail';
const CAPTCHA_ERROR_UNCAUGHT_EXCEPTION = 'captcha-error-uncaught-exception';

class PromiseCaptcha {
    constructor() {
        /**
         * @type {string}
         */
        this.siteKey = global.sat.gRecaptchaSitekey;

        this.isCaptchaRendered = false;

        this.captchaApiUrl = 'https://www.google.com/recaptcha/api.js';

        this.scriptLoader = new ScriptLoadingHelper(this.captchaApiUrl, isCaptchaApiLoaded);

        this.containerId = 'promiseCaptchaContainer';

        /**
         * @type {?String}
         */
        this.captchaWidgetId = null;

        this.captchaCallback = null;

        this.token = null;

        this.hasToken = false;
    }

    #createRecaptchaContainer() {
        let captchaContainer = document.getElementById(this.containerId);

        if (captchaContainer === null) {
            captchaContainer = document.createElement('div');

            // make sure the captcha box is visible and above other content
            captchaContainer.style = '';
            captchaContainer.id = this.containerId;

            // prepend because google renders the challenge overlay (the thing where you have to click on bikes)
            // relative to this element's position for some bizarre reason,
            // i.e. if the captcha container is at the very end of the document body,
            // the challenge overlay will end up there as well
            document.body.prepend(captchaContainer);
        }
    }

    /**
     * @param {Function} resolve
     */
    #renderCaptcha(resolve) {
        this.captchaCallback = resolve;

        if (!this.isCaptchaRendered) {
            const captchaConfig = {
                sitekey: this.siteKey,
                size: 'invisible',
                callback: (token) => {
                    this.token = token;
                    this.captchaCallback(token);
                },
            };

            this.captchaWidgetId = grecaptcha.render(this.containerId, captchaConfig);

            this.isCaptchaRendered = true;
        } else {
            resolve(this.token);
        }
    }

    /**
     * @return {Promise}
     */
    getToken() {
        return new Promise((resolve, reject) => {
            this.scriptLoader
                .loadScript()
                .then(() => {
                    this.#createRecaptchaContainer();

                    if (testIsNotNull(this.captchaWidgetId)) {
                        grecaptcha.reset(this.captchaWidgetId);
                    }

                    this.#renderCaptcha((token) => {
                        this.hasToken = true;
                        resolve(token);
                    });

                    this.hasToken = false;
                    grecaptcha.execute(this.captchaWidgetId);

                    this.#handleCaptchaChallengeRendering();
                })
                .catch((error) => {
                    if (error === SCRIPT_LOADER_LOAD_ERROR || error === SCRIPT_LOADER_OTHER_ERROR) {
                        reject(CAPTCHA_ERROR_SCRIPT_LOAD_FAIL);

                        return;
                    }

                    globalLogger.log('promise captcha error', error);

                    reject(CAPTCHA_ERROR_UNCAUGHT_EXCEPTION);
                });
        });
    }

    /**
     * For some reason google decided to use 'position: absolute;' for the captcha challenge container,
     * but we want to use 'position: fixed;' instead to make sure that the challenge container modal
     * can't be scrolled out of the viewport
     */
    #handleCaptchaChallengeRendering() {
        let count = 0;
        let maxCount = 10;

        let _this = this;

        function handleCaptchaChallenge() {
            setTimeout(() => {
                count++;

                if (_this.hasToken) {
                    return;
                }

                let iframes = document.getElementsByTagName('iframe');

                /** @type {?HTMLElement} */
                let captchaChallengeIframe = null;

                for (let iframe of iframes) {
                    if (!testIsNonEmptyString(iframe.title)) {
                        continue;
                    }

                    // the title 'recaptcha challenge' is the only property by which we can
                    // (more or less) reliably identify the captcha challenge modal/iframe
                    if (iframe.title.includes('recaptcha challenge')) {
                        captchaChallengeIframe = iframe;

                        break;
                    }
                }

                if (captchaChallengeIframe !== null) {
                    captchaChallengeIframe.parentElement.style.position = 'fixed';

                    return;
                }

                if (count >= maxCount) {
                    return;
                }

                handleCaptchaChallenge();
            }, 200);
        }

        handleCaptchaChallenge();
    }
}

if (!global.gPromiseCaptcha) {
    global.gPromiseCaptcha = new PromiseCaptcha();
}

const promiseCaptcha = global.gPromiseCaptcha;

export { promiseCaptcha };
export { CAPTCHA_ERROR_SCRIPT_LOAD_FAIL };
export { CAPTCHA_ERROR_UNCAUGHT_EXCEPTION };
