import { globalLogger } from '../../../helper/global-logger';
import { testIsFunction, testIsUndefined } from '../../../../lib/test-and-assert/test-base';

const SCRIPT_LOADER_LOAD_ERROR = 'script-loader-load-error';
const SCRIPT_LOADER_OTHER_ERROR = 'script-loader-other-error';

/**
 * @param {String} src
 * @return {Promise}
 */
function loadScript(src) {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');

        script.src = src;
        script.type = 'text/javascript';
        script.async = true;

        script.onload = resolve;
        script.onerror = () => {
            reject(SCRIPT_LOADER_LOAD_ERROR);
        };

        document.body.appendChild(script);
    });
}

/**
 * @param {ScriptLoadingHelper~isScriptLoadedFunction} isScriptLoaded
 * @return {Promise}
 */
function waitUntilScriptIsLoaded(isScriptLoaded) {
    const startTime_s = Date.now() / 1000;

    return new Promise((resolve, reject) => {
        function wait() {
            if (isScriptLoaded()) {
                resolve();

                return;
            }

            const nowTime_s = Date.now() / 1000;
            const duration_s = nowTime_s - startTime_s;

            if (duration_s > 10) {
                reject();

                return;
            }

            setTimeout(wait, 100);
        }

        wait();
    });
}

/**
 * @callback ScriptLoadingHelper~isScriptLoadedFunction
 * @return {Boolean}
 */

class ScriptLoadingHelper {
    /**
     * @param {string} url
     * @param {?ScriptLoadingHelper~isScriptLoadedFunction} [isScriptLoaded=null]
     */
    constructor(url, isScriptLoaded) {
        if (testIsUndefined(isScriptLoaded)) {
            isScriptLoaded = null;
        }

        this.isScriptLoaded = isScriptLoaded;

        /**
         * @type {?Promise}
         */
        this.loadingPromise = null;

        this.url = url;

        this.loadingFailed = false;
    }

    /**
     * @returns {Promise}
     */
    loadScript() {
        if (this.loadingPromise !== null && this.loadingFailed === false) {
            return this.loadingPromise;
        }

        this.loadingPromise = loadScript(this.url)
            .then(() => {
                this.loadingFailed = false;

                if (!testIsFunction(this.isScriptLoaded)) {
                    // resolve promise if no function to verify that the script was fully loaded is defined
                    return Promise.resolve();
                }

                return waitUntilScriptIsLoaded(this.isScriptLoaded);
            })
            .catch((error) => {
                globalLogger.log('PromiseScriptLoader loading script failed', error);

                this.loadingFailed = true;

                if (error === SCRIPT_LOADER_LOAD_ERROR) {
                    return Promise.reject(SCRIPT_LOADER_LOAD_ERROR);
                }

                return Promise.reject(SCRIPT_LOADER_OTHER_ERROR);
            });

        return this.loadingPromise;
    }
}

export { loadScript };
export { ScriptLoadingHelper };
export { SCRIPT_LOADER_LOAD_ERROR };
export { SCRIPT_LOADER_OTHER_ERROR };
