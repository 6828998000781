import axios from 'axios';
import { testIsEmptyString, testIsNotUndefined, testIsString, testIsUndefined } from '../test-and-assert/test-base';
import { globalLogger } from '../../qaamgo/helper/global-logger';
import { assertIsNonEmptyString, assertIsObject } from '../test-and-assert/assert-base';

class AxiosWrapper {
    constructor() {
        this.axios = axios;
    }

    /**
     * @param {String} url
     * @param {Object} [data]
     * @param {AxiosConfig} [config]
     *
     * @return {Promise<AxiosResponse>}
     */
    get(url, data, config) {
        /**
         * @type {AxiosConfig}
         */
        let requestConfig = {
            method: 'get',
            url: url,
            params: {
                _: Date.now(),
            },
        };

        if (testIsNotUndefined(data)) {
            assertIsObject(data);

            if (testIsNotUndefined(data._)) {
                throw new Error("the GET-parameter key '_' is reserved for cache-busting purposes!");
            }

            requestConfig.params = Object.assign(requestConfig.params, data);
        }

        if (testIsNotUndefined(config)) {
            assertIsObject(config);

            if (testIsString(config.url) || testIsString(config.baseUrl)) {
                throw new Error('setting a url in config is not supported for this function!');
            }

            requestConfig = Object.assign(requestConfig, config);
        }

        return this.request(requestConfig);
    }

    /**
     * @param {String} url
     * @param {Object} [data]
     * @param {AxiosConfig} [config]
     *
     * @return {Promise<AxiosResponse>}
     */
    post(url, data, config) {
        /**
         * @type {AxiosConfig}
         */
        let requestConfig = {
            method: 'post',
            url: url,
        };

        if (testIsNotUndefined(data)) {
            assertIsObject(data);

            requestConfig.data = data;
        }

        if (testIsNotUndefined(config)) {
            assertIsObject(config);

            if (testIsString(config.url) || testIsString(config.baseUrl)) {
                throw new Error('setting a url in config is not supported for this function!');
            }

            requestConfig = Object.assign(requestConfig, config);
        }

        return this.request(requestConfig);
    }

    /**
     * @param {AxiosConfig} config
     * @param {Boolean} [cacheBusting=true]
     *
     * @return {Promise<AxiosResponse>}
     */
    request(config, cacheBusting) {
        if (testIsUndefined(cacheBusting)) {
            cacheBusting = true;
        }

        assertIsNonEmptyString(config.method, 'missing method!');

        if (config.method !== 'get' && config.method !== 'post') {
            throw new Error("method can only be 'get' or 'post'!");
        }

        if (testIsEmptyString(config.url) && testIsEmptyString(config.baseUrl)) {
            throw new Error('missing url');
        }

        const defaultConfig = {
            withCredentials: true,
            timeout: 59000,
        };

        if (cacheBusting && config.method === 'get') {
            defaultConfig.params = {
                _: Date.now(),
            };
        }

        const requestConfig = Object.assign(defaultConfig, config);

        return this.axios.request(requestConfig).catch((/**AxiosError*/ error) => {
            error.isStatusError = false;
            error.isMissingResponseError = false;
            error.isUnknownError = false;

            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                error.isStatusError = true;

                globalLogger.log(`axios wrapper error - status ${error.response.status}`, error);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest
                error.isMissingResponseError = true;

                globalLogger.log('axios wrapper error - no response', error);
            } else {
                // Something happened in setting up the request that triggered an Error
                error.isUnknownError = true;

                globalLogger.log('axios wrapper error - unknown', error);
            }

            return Promise.reject(error);
        });
    }
}

if (!global.gAxios) {
    global.gAxios = new AxiosWrapper();
}

const axiosWrapper = global.gAxios;

export { axiosWrapper };
