import { axiosWrapper } from '../../lib/axios-wrapper/axios-wrapper';
import { satGlobals } from '../../qaamgo/sat/helper/sat-globals';
import { promiseCaptcha } from '../captcha/promise-captcha';
import { PromiseQueue } from '../../lib/promise-queue/promise-queue';
import { testIsNull, testIsUndefined } from '../../lib/test-and-assert/test-base';

class ApiRequestManager {
    constructor() {
        this.axios = axiosWrapper;

        this.captchaRequestQueue = new PromiseQueue();
    }

    /**
     * @param {String} endpoint
     * @param {Object} [data]
     * @param {AxiosConfig} [config]
     *
     * @return {Promise<AxiosResponse>}
     */
    get(endpoint, data, config) {
        const url = satGlobals.getApiUrl(endpoint);

        return this.axios.get(url, data, config);
    }

    /**
     * @param {String} endpoint
     * @param {Object} [data]
     * @param {AxiosConfig} [config]
     *
     * @return {Promise<AxiosResponse>}
     */
    post(endpoint, data, config) {
        const url = satGlobals.getApiUrl(endpoint);

        return this.axios.post(url, data, config);
    }

    /**
     * @param {String} endpoint
     * @param {Object} [data]
     * @param {AxiosConfig} [config]
     *
     * @return {Promise<AxiosResponse>}
     */
    postWithCaptcha(endpoint, data, config) {
        // use promise queue for captcha token retrieval, this is done because:
        // - captcha tokens can only be validated once
        // - captcha tokens expire after 2min
        // - the captcha container can only generate one token at a time
        // - we have no idea if generating a new captcha token invalidates
        //   all old tokens (official documentation is unhelpfully vague about this)
        //
        // therefore we must only allow one request at a time to retrieve a captcha token
        if (testIsUndefined(data) || testIsNull(data)) {
            data = {};
        }

        return this.captchaRequestQueue.queuePromise(() => {
            return this.#doPostWithCaptcha(endpoint, data, config);
        });
    }

    /**
     * @param {String} endpoint
     * @param {Object} [data]
     * @param {AxiosConfig} [config]
     *
     * @return {Promise<AxiosResponse>}
     */
    #doPostWithCaptcha(endpoint, data, config) {
        return this.post(endpoint, data, config).catch((/** AxiosError */ error) => {
            // if error is not due to missing captcha -> reject
            if (error?.response?.data?.message !== 'invalid_captcha') {
                return Promise.reject(error);
            }

            // error is due to missing captcha -> get captcha token and try request again
            return promiseCaptcha.getToken().then((captchaToken) => {
                data.captcha = captchaToken;

                return this.post(endpoint, data, config);
            });
        });
    }

    request(config) {
        return this.axios.request(config);
    }
}

if (!global.gApiRequestManager) {
    global.gApiRequestManager = new ApiRequestManager();
}

/** @type {ApiRequestManager} */
const apiRequestManager = global.gApiRequestManager;

export { apiRequestManager };
