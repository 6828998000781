class PromiseQueue {
    constructor() {
        /**
         * @type {Promise[]}
         */
        this.promiseQueue = [];
    }

    /**
     * @callback PromiseQueue~callback
     * @return {Promise}
     */

    /**
     * @param {PromiseQueue~callback} promiseCallback
     *
     * @return {Promise}
     */
    queuePromise(promiseCallback) {
        if (this.promiseQueue.length === 0) {
            const queuedPromise = promiseCallback();

            this.promiseQueue.push(queuedPromise);

            return queuedPromise;
        }

        const lastIndex = this.promiseQueue.length - 1;

        const promiseToQueueAfter = this.promiseQueue[lastIndex];

        const queuedPromise = new Promise((resolve, reject) => {
            promiseToQueueAfter.finally(() => {
                this.promiseQueue.shift();

                promiseCallback().then(resolve).catch(reject);
            });
        });

        this.promiseQueue.push(queuedPromise);

        return queuedPromise;
    }
}

export { PromiseQueue };
